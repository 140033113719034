var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: _vm.title },
      }),
      _vm.areTagsLoading || _vm.editInProgressCount > 0
        ? _c(
            "div",
            [
              _vm.editInProgressCount > 0
                ? _c("v-progress-linear", {
                    attrs: { value: _vm.editProgressPercentage },
                  })
                : _vm._e(),
              _c("v-skeleton-loader", {
                attrs: { type: "chip, article, article" },
              }),
            ],
            1
          )
        : [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { md: "12" } },
                      [
                        _c(
                          "v-expansion-panels",
                          [
                            _c(
                              "v-expansion-panel",
                              [
                                _c("v-expansion-panel-header", [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        "You are editing " +
                                          _vm._s(_vm.templateIds.length) +
                                          " templates, expand to review them."
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "overflow-y-auto",
                                        attrs: { justify: "center" },
                                      },
                                      [
                                        _vm.areTemplatesLoading
                                          ? _vm._l(8, function (i) {
                                              return _c("v-skeleton-loader", {
                                                key: i,
                                                attrs: {
                                                  type: "article, actions",
                                                },
                                              })
                                            })
                                          : _vm._l(
                                              _vm.filteredMessageTemplates,
                                              function (item) {
                                                return _c(
                                                  "template-card-wrapper",
                                                  {
                                                    key: item.TemplateID,
                                                    staticClass:
                                                      "d-flex flex-column",
                                                    attrs: {
                                                      template: item,
                                                      "waypoint-id":
                                                        _vm.waypointId,
                                                      selected: false,
                                                      showActions: false,
                                                      elevation: "5",
                                                    },
                                                  }
                                                )
                                              }
                                            ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("form", { staticClass: "template-form fill-height" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "form-input large block-xs--xs flex-grow-0" },
                  [
                    _c(
                      "label",
                      { staticClass: "required label--template-form" },
                      [_vm._v("Message Template")]
                    ),
                    _c("template-editor", {
                      attrs: {
                        label: "Template",
                        placeholders: _vm.templateTags,
                      },
                      model: {
                        value: _vm.body,
                        callback: function ($$v) {
                          _vm.body = $$v
                        },
                        expression: "body",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }