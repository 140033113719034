<template>
  <div>
      <ContextTitle :passedActions="titleBarActions" :title="title" />
      <div v-if="areTagsLoading || editInProgressCount > 0">
          <v-progress-linear
              v-if="editInProgressCount > 0"
              :value="editProgressPercentage"/>
          <v-skeleton-loader
              type="chip, article, article"
          />
      </div>
      <template v-else>
          <v-container fluid>
              <v-row no-gutters>
                  <v-col md="12">
                      <v-expansion-panels>
                          <v-expansion-panel>
                              <v-expansion-panel-header>
                                  <div class="font-weight-bold">You are editing {{templateIds.length}} templates, expand to review them.</div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                  <v-row justify="center" class="overflow-y-auto" >
                                      <template v-if="areTemplatesLoading">
                                          <v-skeleton-loader
                                              v-for="i in 8"
                                              :key="i"
                                              type="article, actions"
                                          />
                                      </template>
                                      <template v-else>
                                              <template-card-wrapper
                                                  v-for="item in filteredMessageTemplates"
                                                  :key="item.TemplateID"
                                                  :template="item"
                                                  :waypoint-id="waypointId"
                                                  :selected="false"
                                                  :showActions="false"
                                                  elevation="5"
                                                  class="d-flex flex-column"
                                              />
                                      </template>
                                  </v-row>
                              </v-expansion-panel-content>
                          </v-expansion-panel>
                      </v-expansion-panels>
                  </v-col>
              </v-row>
          </v-container>
          <form class="template-form fill-height">
              <div class="col-12">
                  <div class="form-input large block-xs--xs flex-grow-0">
                      <label class="required label--template-form">Message Template</label>
                      <template-editor
                          v-model="body"
                          label="Template"
                          :placeholders="templateTags"
                      />
                  </div>
              </div>
          </form>
      </template>
  </div>
</template>

<script>
import ContextTitle from "components/context_title";
import LineItem from "components/line_item";
import TemplateEditor from '@/components/template_editor';
import {mapActions, mapGetters} from "vuex";
import TemplateCardWrapper from "components/messaging/TemplateCardWrapper";
import {OperationStatus} from "helpers/common";

export default {
    name: "batch_edit",
    components: {
        ContextTitle,
        LineItem,
        TemplateEditor,
        TemplateCardWrapper,
    },
    data(){
        return {
            previousRoute: null,
            body: ''
        };
    },
    mounted() {
        if (this.templateMetadataStatus === OperationStatus.Unknown) {
            this.getMessageTemplateMetaData();
        }

        if (this.customerSmsTemplatesStatus === OperationStatus.Unknown || !this.templateById(this.id)) {
            this.getCustomerSMSMessageTemplates(this.waypointId);
        }

        if (this.templateTagsStatus === OperationStatus.Unknown || this.templateTagsStatus === OperationStatus.Failed) {
            this.getCustomerSMSMessageTemplateTags();
        }

        this.getAirports();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from
        })
    },
    computed: {
        ...mapGetters("MessagingStore", [
            "templateById",
            "customerSmsTemplatesStatus",
            "customerSmsTemplates",
            "templateMetadataStatus",
            "templateTagsStatus",
            "templateTags",
            "templateEditStatus"
        ]),
        templateIds() {
            return Array.isArray(this.$route.query.templateIds)
                ? this.$route.query.templateIds
                : [this.$route.query.templateIds];
        },
        waypointId() {
            return Number.parseInt(this.$route.params.waypointID, 10);
        },
        titleBarActions() {
            const cancel = {
                type: "cancel",
                display: "Cancel",
                run: this.cancel,
            };

            const save = {
                type: "proceed",
                display: "Save",
                run: this.save,
            };

            if (this.editInProgressCount > 0) {
                return [cancel];
            } else {
                return [cancel, save];
            }
        },
        title() {
            return `Bulk Edit ${this.templateIds.length} Message template`;
        },
        filteredMessageTemplates() {
            return this
                .customerSmsTemplates
                .filter(x => _.includes(this.templateIds, x.TemplateID));
        },
        areTagsLoading() {
            return this.templateTagsStatus === OperationStatus.InProgress
                || this.templateTagsStatus === OperationStatus.Unknown;
        },
        areTemplatesLoading() {
            return this.customerSmsTemplatesStatus === OperationStatus.InProgress
                || this.customerSmsTemplatesStatus === OperationStatus.Unknown
        },
        editInProgressCount() {
            return this.templateIds.filter(x => this.templateEditStatus(x) === OperationStatus.InProgress).length;
        },
        editProgressPercentage() {
            const finishedCount = this
                .templateIds
                .filter(x => this.templateEditStatus(x) === OperationStatus.Succeeded
                    || this.templateEditStatus(x) === OperationStatus.Failed)
                .length;
            return finishedCount / this.templateIds.length * 100;
        }
    },
    methods: {
        ...mapActions("MessagingStore", [
            "getCustomerSMSMessageTemplates",
            "getMessageTemplateMetaData",
            "getCustomerSMSMessageTemplateTags",
            "batchEditTemplates",
        ]),
        ...mapActions("AirportStore", [
            "getAirports",
        ]),
        async save() {
            const { isSucceeded, templateIds } = await this.batchEditTemplates({
                waypointId: this.waypointId,
                redirectAfterCreate: true,
                templates: this.templateIds.map(x => ({
                    ...this.templateById(x),
                    MessageBodyTemplate: this.body,
                })),
            });

            if (isSucceeded) {
                this.router.push({
                    name: 'messaging-batch-edit',
                    params: {
                        waypointID: this.waypointId,
                    },
                    query: {
                        templateIds,
                    },
                });
                await this.getCustomerSMSMessageTemplates(this.waypointId);
            }
        },
        cancel() {
            this.navigateBackToMessaging();
        },
        navigateBackToMessaging() {
            this.$router.push(this.previousRoute && this.previousRoute.name === 'messaging'
                ? this.previousRoute
                : {name: 'messaging'}
            );
        },
    }
}
</script>

<style scoped>

</style>
